import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "errors", "select", "selectedFrame", "fields" ]
  static values = {
    equipmentUrl: String
  }

  connect() {
    this.element.addEventListener("turbo:submit-end", (event) => {
      if (event.detail.success) {
        this.clear()
      }
    })
  }

  select(e) {
    const selectedValue = e.currentTarget.value
    const updatedUrl = this.equipmentUrlValue + `?equipment_id=${selectedValue}`

    // Remove errors, hide the fields, then remove all except the placeholder.
    this.errorsTarget.innerHTML = ""
    this.fieldsTarget.classList.add("hidden")
    this.selectedFrameTarget.querySelectorAll("*:not(.hidden):not(.hidden *)").forEach((element) => { element.remove() })

    // When the frame has loaded, show the fields only if we have an equipment selected.
    this.selectedFrameTarget.addEventListener("turbo:frame-load", () => {
      if (selectedValue) {
        this.fieldsTarget.classList.remove("hidden")
      } else {
        this.fieldsTarget.classList.add("hidden")
      }
    }, { once: true })

    // Show the placeholder and update the frame src
    if (selectedValue) {
      this.selectedFrameTarget.querySelector("turbo-frame > .hidden").classList.remove("hidden")
      this.selectedFrameTarget.src = updatedUrl
    }
  }

  cancel() {
    this.clear()
  }

  /** FUNCTIONS **/

  clear() {
    this.errorsTarget.innerHTML = ""
    this.selectTarget.value = ""

    const event = new Event("change", { bubbles: true })
    this.selectTarget.dispatchEvent(event)
  }
}
