import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input" ]
  static values = {
    debounceTime: Number
  }

  connect() {
    this.debounceTimeout = null
    this.debounceTimeValue = this.debounceTimeValue || 2000
  }

  inputTargetConnected(input) {
    if (input.tagName.toLowerCase() === "select") {
      input.addEventListener("change", this.submitForm.bind(this, input))
    } else {
      input.addEventListener("input", this.submitForm.bind(this, input))
    }
  }

  inputTargetDisconnected(input) {
    if (input.tagName.toLowerCase() === "select") {
      input.removeEventListener("change", this.submitForm.bind(this, input))
    } else {
      input.removeEventListener("input", this.submitForm.bind(this, input))
    }
  }

  /** FUNCTIONS **/

  submitForm() {
    clearTimeout(this.debounceTimeout)

    this.debounceTimeout = setTimeout(() => {
      this.element.requestSubmit()
    }, this.debounceTimeValue)
  }
}
